import React from 'react';

import Occupancy from '../../components/Occupancy/Occupancy'
import Footer from '../../components/Footer/Footer'
import Layout from '../../components/Layout/Layout';

const Dashboard = () => {

  return (
    <Layout>
      <Occupancy />
      <Footer />
    </Layout>
  )
}

export default Dashboard;
