import React, { useContext } from 'react';

import withSizePicker from '../../hoc/withSizePicker/withSizePicker';
import { CustomersContext } from '../../context/CustomersContext';
import { EntranceContext } from '../../context/EntranceContext';
import { percentage, maskedPercentage } from '../../utils/percentage'

import styles from './Occupancy.module.scss'
import Chart from '../_common/Chart/Chart';
import Title from './Title/Title';

const Occupancy = props => {

  const { width, height } = props;

  const scale = Math.min(
    width / 1366,
    height * 0.7 / 715
  );

  const customers = useContext(CustomersContext);
  const entrances = useContext(EntranceContext);

  const { occupancy } = entrances ? entrances : {
    occupancy: 0,
  };

  const {
    occupancy_threshold,
    // range_danger,
    range_warning,
    range_normal
  } = customers ? customers :
      {
        occupancy_threshold: 0,
        // range_danger: '[80, 100]',
        range_warning: '[60, 80]',
        range_normal: '[0, 60]'
      };

  // const danger = range_danger.substring(1, range_danger.length - 1).split(',');
  const warning = range_warning.substring(1, range_warning.length - 1).split(',');
  const normal = range_normal.substring(1, range_normal.length - 1).split(',');

  let title, color, secondTitle;

  if (occupancy <= normal[1]) {
    title = 'Enter';
    secondTitle = 'Entrer';
    color = '#00B91C';
  }
  else if (occupancy <= warning[1]) {
    title = 'Enter';
    secondTitle = 'Entrer';
    color = '#E8AD37';
  }
  else {
    title = 'Wait';
    secondTitle = 'Attendre';
    color = '#E7574C';
  }

  // console.log('Occupancy', occupancy, occupancy_threshold);

  let occ_percent = percentage(occupancy, occupancy_threshold);

  // FOR MASKING
  let toMask = customers.mask_zero_value;
  let maskThreshold = customers.mask_threshold;

  let maskedPercent = maskedPercentage(toMask, occ_percent, maskThreshold);

  return (
    <div className={styles.wrapper}>
      {customers &&
        <div
          className={styles.main}
          style={{
            transform: "translate(-50%, -50%) scale(" + scale + ")"
          }}
        >
          <Chart
            value={maskedPercent}
            width={80}
            size={540}
            color={color}
          />
          <Title
            title={title}
            color={color}
            secondText={secondTitle}
          />
        </div>}
    </div>
  )
}

export default withSizePicker(Occupancy);
