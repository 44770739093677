export const percentage = (numerator, denominator) => {
    if (denominator === 0) {
        return 0
    }

    let percent = parseFloat(numerator / denominator).toFixed(2) * 100;

    let inRange = between(percent, 0, 100);

    if (inRange) {
        return percent;
    }

    // If out of range, we restrict to 100
    return 100;
};

// Does include the lower bound, and the upper bound
function between(x, min, max) {
    return x >= min && x <= max;
}

// Does include the lower bound, but not the upper bound
function betweenExclusive(x, min, max) {
    return x >= min && x < max;
}

// We will mask the percentage, if less than `maskThreshold`
export const maskedPercentage = (showMask, occupancyPercent, maskThreshold) => {
    if(showMask && betweenExclusive(occupancyPercent, 0, maskThreshold)) {
        let maskedPercent = '<' + maskThreshold;
        return maskedPercent;
    }

    return occupancyPercent;
}