import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types'
import TextLoop from "react-text-loop";

const TextSwitcher = ({ text, secondText, interval }) => {
    const [displayText, setDisplayText] = useState({
        textToShow: text,
    });

    const DURATION = interval * 1000;

    useEffect(() => {
        const timeout = setInterval(() => {
            if (displayText.textToShow === text) {
                setDisplayText({
                    textToShow: secondText
                });
            } else {
                setDisplayText({
                    textToShow: text
                });
            }
        }, DURATION);
        return () => clearInterval(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayText]);

    return (
        <>
            <TextLoop children={[displayText.textToShow, displayText.textToShow]} interval={DURATION}>
            </TextLoop>
        </>
    )
}

TextSwitcher.defaultProps = {
    text: 'Some text',
    secondText: 'Next',
    interval: 5,
}

TextSwitcher.propTypes = {
    text: PropTypes.string,
    secondText: PropTypes.string,
    interval: PropTypes.number,
}

export default TextSwitcher
