import { gql } from "@apollo/client";

export const OCCUPANCY_SUBSCRIPTION = gql`
  subscription Occupancy {
    entrances(order_by: { created_time: desc }, limit: 1) {
      occupancy
    }
  }
`;

export const OCCUPANCY_SUBSCRIPTION_PROD = gql`
  subscription Occupancy {
    loblaws_entrances(limit: 1, order_by: { created_time: desc }) {
      occupancy
    }
  }
`;

export const CUSTOMER_SUBSCRIPTION = gql`
  subscription getCustomers {
    customers {
      id
      range_normal
      range_warning
      reminder_message
      show_floor_wise_description
      waiting_message
      range_danger
      occupancy_threshold
      name
      logo_url
      address
      mask_zero_value
      mask_threshold
    }
  }
`;

export const PROMOTIONS_SUBSCRIPTION = gql`
  subscription getPromotions {
    promotions {
      cust_id
      promotion_en
      promotion_fr
    }
  }
`;
