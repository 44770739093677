import React, { useState } from 'react';

import styles from './Footer.module.scss'
import Info from './Info/Info';
import Floors from './Floors/Floors';

const Footer = () => {

  const [type] = useState('info'); // setType

  return (
    <div className={styles.main} >
      {type === 'info' ? 
        <Info/> 
        : 
        <Floors/> 
      }
      <div className={styles.copyright}>Powered by BRAIYT</div>
    </div>
  )
}

export default Footer;
