import React, { useContext } from 'react';

import { CustomersContext } from '../../../context/CustomersContext';
import { PromotionsContext } from '../../../context/PromotionsContext';

import styles from './Info.module.scss';
import Logo from '../../../assets/icons/logo.png'

import TextSwitcher from '../../animation/TextSwitcher'

const Info = () => {

  const customers = useContext(CustomersContext);
  const promotions = useContext(PromotionsContext);

  const { address, logo_url, name } = customers && promotions ? customers :
    { address: '', logo_url: '', name: 'Logo' };

  const { promotion_en } = customers && promotions ? promotions :
    { promotion_en: null };

  const { promotion_fr } = customers && promotions ? promotions :
    { promotion_fr: null };

  return (
    <div className={styles.main}>

      {address ?
        <div className={styles.column}>
          <img src={logo_url || Logo} alt={name} />
          <div className={styles.address}>{address}</div>
        </div>
        :
        <div className={styles.column}></div>
      }

      {promotion_en ?
        <div className={styles.column}>
          <div className={styles.title}>
            <TextSwitcher text={promotion_en.promotion_title} secondText={promotion_fr.promotion_title}></TextSwitcher>
          </div>

          <div className={styles.text}>{promotion_en.price}</div>

          <div className={styles.text}>
            <TextSwitcher text={promotion_en.promotion_subtitle} secondText={promotion_fr.promotion_subtitle}></TextSwitcher>
          </div>
        </div>
        :
        <div className={styles.column}></div>
      }

    </div>
  )
}

export default Info;
