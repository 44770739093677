import React, { useContext } from 'react';

import { CustomersContext } from '../../../context/CustomersContext';

import styles from './Title.module.scss';
import Clock from '../../../assets/icons/clock.svg'
import Mask from '../../../assets/icons/mask.svg'
import Pointer from '../../../assets/icons/pointer.svg'

import TextSwitcher from '../../animation/TextSwitcher'

const Title = props => {

  const { title, color, secondText } = props;

  const customers = useContext(CustomersContext);

  const {
    reminder_message,
    waiting_message,
  } = customers ? customers :
      {
        reminder_message: {
          message_en: '',
          message_fr: ''
        },
        waiting_message: {
          message_en: '',
          message_fr: ''
        }
      };

  return (
    <div className={styles.main}>

      <div
        className={styles.header}
        style={{ color: color }}
      >
        <TextSwitcher text={title} secondText={secondText}></TextSwitcher>
      </div>

      { title !== 'Enter' && waiting_message.message_en &&
        <div className={styles.list_item}>
          <div>
            <img src={Clock} alt='Clock' />
          </div>
          <span>
            <TextSwitcher text={waiting_message.message_en} secondText={waiting_message.message_fr}></TextSwitcher>
          </span>
        </div>
      }

      { reminder_message.message_en &&
        <div className={styles.list_item}>
          <div>
            <img src={Mask} alt='Mask' />
          </div>
          <span>
            <TextSwitcher text={reminder_message.message_en} secondText={reminder_message.message_fr}></TextSwitcher>
          </span>
        </div>
      }

      { false &&
        <div className={styles.list_item}>
          <div>
            <img src={Pointer} alt='Pointer' />
          </div>
          <span>Ground floor</span>
        </div>
      }

    </div>
  )
}

export default Title;
