
import React from 'react';

import { CustomersProvider } from '../../context/CustomersContext';
import Dashboard from '../../containers/dashboard/Dashboard'
import { PromotionsProvider } from '../../context/PromotionsContext';

const Promotions = () => {

    return (
        <PromotionsProvider>
            <CustomersProvider>
                <Dashboard />
            </CustomersProvider>
        </PromotionsProvider>
    )
}

export default Promotions;