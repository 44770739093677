import React, { createContext } from "react";

import { useSubscription } from "@apollo/client";
import {
  OCCUPANCY_SUBSCRIPTION,
  OCCUPANCY_SUBSCRIPTION_PROD,
} from "../GraphQL/Queries";

export const EntranceContext = createContext();

// This context provider is passed to any component requiring the context
export const EntranceProvider = ({ children }) => {
  const { loading, error, data } = useSubscription(OCCUPANCY_SUBSCRIPTION);

  const entranceData = data ? data.entrances[0] : null;
  // console.log('entranceData', entranceData);

  if (loading) {
    return <>Loading ...</>;
  }

  if (error) {
    return <>Something went wrong ...</>;
  }

  return (
    <EntranceContext.Provider value={entranceData}>
      {children}
    </EntranceContext.Provider>
  );
};

export const ProdEntranceProvider = ({ children }) => {
  const { loading, error, data } = useSubscription(OCCUPANCY_SUBSCRIPTION_PROD);

  const entranceData = data ? data.loblaws_entrances[0] : null;
  // console.log('entranceData', entranceData);

  if (loading) {
    return <>Loading ...</>;
  }

  if (error) {
    return <>Something went wrong ...</>;
  }

  return (
    <EntranceContext.Provider value={entranceData}>
      {children}
    </EntranceContext.Provider>
  );
};
