import React, { useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from "apollo-client";

import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';

import Promotions from './containers/common/Promotions'

import { EntranceProvider, ProdEntranceProvider } from './context/EntranceContext';
import { onMessageListener } from './firebaseInit'
import Notifications from './components/Notifications/Notifications'

const { REACT_APP_HTTP_URL, REACT_APP_WS_URL, REACT_APP_HASURA_SECRET, REACT_APP_ENV } = process.env
const isProd = REACT_APP_ENV === 'prod';

const HTTP_URL = REACT_APP_HTTP_URL;
const WS_URL = REACT_APP_WS_URL;
const HASURA_SECRET = REACT_APP_HASURA_SECRET;

const httpLink = new HttpLink({
  uri: HTTP_URL,
  headers: {
    'x-hasura-admin-secret': HASURA_SECRET
  }
});

const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        "content-type": "application/json",
        'x-hasura-admin-secret': HASURA_SECRET
      }
    }
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    // console.log({ query: query, kind: kind, operation: operation });
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

const App = () => {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  console.log(show, notification)

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  return (
    <ApolloProvider client={client}>
      {isProd ? <ProdEntranceProvider>
        <Notifications />
        <Promotions />
      </ProdEntranceProvider> : <EntranceProvider>
        <Promotions />
      </EntranceProvider>}
    </ApolloProvider>
  );
}

export default App;
