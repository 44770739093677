import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

import styles from './Chart.module.scss'

import TextSwitcher from '../../animation/TextSwitcher'

const Chart = props => {

  const { value, width, size, style = {}, small, color } = props;

  const COLORS = [color, '#E0E0E0']
  const pieData = [{ value: value }, { value: 100 - value }];

  return (
    <div className={styles.main}>
      <PieChart width={size} height={size}
        style={{ ...style }}
      >
        <Pie
          data={[{ value: 100 }]}
          innerRadius={size / 2 - width}
          outerRadius={size / 2}
          fill={COLORS[1]}
          isAnimationActive={false}
          blendStroke
        >
        </Pie>
        <Pie
          data={pieData}
          innerRadius={size / 2 - width}
          outerRadius={size / 2}
          startAngle={90}
          endAngle={-270}
          cornerRadius={50}
          blendStroke
        >
          {
            pieData.map((entry, index) => <Cell key={entry} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
      </PieChart>

      {small ?

        <div className={styles.label}>
          <div style={{ color: COLORS[0] }} className={styles.small_header}>
            {value}%
          </div>
        </div>

        :

        <div className={styles.label}>
          <div style={{ color: COLORS[0] }} className={styles.header}>
            {value}%
          </div>
          <div className={styles.text}>
            <TextSwitcher text="occupancy" secondText="occupation"></TextSwitcher>
          </div>
        </div>

      }
    </div>
  )
}

export default Chart;
