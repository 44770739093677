import React, { useState, useEffect } from "react";
import { getToken } from "../../firebaseInit.js";
import { FCM_MUTATION } from "../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";

const { REACT_APP_CUSTOMER_ID, REACT_APP_FCM_CLIENT } = process.env;

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

  const [insertToken, { error }] = useMutation(FCM_MUTATION);
  console.log("Token found", isTokenFound);

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        insertToken({
          variables: {
            client_name: REACT_APP_FCM_CLIENT,
            token_id: REACT_APP_CUSTOMER_ID,
            token_list: [data],
          },
        });
      }
      return data;
    }

    tokenFunc();
  }, [insertToken, setTokenFound]);

  if (error) {
    console.log("Error in the FCM_MUTATION", error);
  }

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
