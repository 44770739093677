import React, { useState, useLayoutEffect } from 'react';

const withSizePicker = ( WrappedComponent ) => {
    return props => {
        
        const [width, setWidth] = useState(window.innerWidth);
        const [height, setHeight] = useState(window.innerHeight);

        useLayoutEffect(() => {
            function handleResize() {
                // console.log(
                //     document.body.offsetWidth, 
                //     document.body.offsetHeight
                // );
                setWidth(document.body.offsetWidth);
                setHeight(document.body.offsetHeight);
            }
        
            window.addEventListener('resize', handleResize);
            window.addEventListener("orientationchange", handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                window.addEventListener("orientationchange", handleResize);
            };
        }, []);
    
        return (
            <React.Fragment>
                <WrappedComponent 
                    {...props} 
                    width={width}
                    height={height}
                />
            </React.Fragment>
        );
    }
}


export default withSizePicker;