import React, { createContext } from "react";

import { useSubscription } from '@apollo/client';
import { CUSTOMER_SUBSCRIPTION } from '../GraphQL/Queries'

const { REACT_APP_CUSTOMER_ID } = process.env

export const CustomersContext = createContext();

// This context provider is passed to any component requiring the context
export const CustomersProvider = ({ children }) => {

  const { loading, error, data } = useSubscription(CUSTOMER_SUBSCRIPTION);

  if (loading) {
    return <>Loading ...</>;
  }

  if (error) {
    return <>Something went wrong ...</>;
  }
  
  const customersData = data.customers;
  const customer = customersData.find(element => element.id === REACT_APP_CUSTOMER_ID);
  // console.log('customersData', customer);

  return (
    <CustomersContext.Provider
      value={customer}
    >
      {children}
    </CustomersContext.Provider>
  );
};