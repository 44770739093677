import React, { createContext } from "react";

import { useSubscription } from '@apollo/client';
import { PROMOTIONS_SUBSCRIPTION } from '../GraphQL/Queries'

export const PromotionsContext = createContext();

// This context provider is passed to any component requiring the context
export const PromotionsProvider = ({ children }) => {

  const { loading, error, data } = useSubscription(PROMOTIONS_SUBSCRIPTION);

  const promotionsData = data ? data.promotions[0] : null;

  // console.log('promotionsData', promotionsData);

  if (loading) {
    return <>Loading ...</>;
  }

  if (error) {
    return <>Something went wrong ...</>;
  }

  return (
    <PromotionsContext.Provider
      value={promotionsData}
    >
      {children}
    </PromotionsContext.Provider>
  );
};