import React from 'react';

import styles from './Floors.module.scss';
import Chart from '../../_common/Chart/Chart';

const Floors = props => {

  const { values } = props;

  const charts = values.map(value => (
    <Chart
      value={value}
      width={10}
      size={130}
      small
    />
  ))

  return (
    <div className={styles.main}>
      <div className={styles.column}>
        {charts[0]}
        <div className={styles.title}>
        1st Floor
        </div>
      </div>
      <div className={styles.column}>
        {charts[1]}
        <div className={styles.title}>
        2nd Floor
        </div>
      </div>
      <div className={styles.column}>
        {charts[2]}
        <div className={styles.title}>
        3rd Floor
        </div>
      </div>
    </div>
  )
}

export default Floors;
