import firebase from 'firebase/app';
import 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyW_XP527wSw0OVoAObwVe7teZj0DkO6M",
  authDomain: "braiyt-pilelogger.firebaseapp.com",
  databaseURL: "https://braiyt-pilelogger.firebaseio.com",
  projectId: "braiyt-pilelogger",
  storageBucket: "braiyt-pilelogger.appspot.com",
  messagingSenderId: "311948688827",
  appId: "1:311948688827:web:4cc4066c554652fa8fd89e",
  measurementId: "G-12TD46DM4B"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = '';

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

// export const getToken = (setTokenFound) => {
//   return messaging.getToken({ vapidKey: publicKey }).then((currentToken) => {
//     if (currentToken) {
//       console.log('current token for client: ', currentToken);
//       setTokenFound(true);
//     } else {
//       console.log('No registration token available. Request permission to generate one.');
//       setTokenFound(false);
//     }
//   }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//   });
// }


export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

